.appContainer{
  max-height: 100vh;
  height: 100vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-wrap: wrap;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.appContainer.fullWidth .workSpace{
  width: 100%;
}

.workSpace{
  display: block;
  position: static;
  height: 100vh;
  width: calc(100% - 283px);
}

@media (max-width: 767px) {
  .workSpace{
    width: 100%;
  }
}

@media (min-width: 1024px) and (max-width: 1500px){
  .darkHeaderTable{
    zoom: 80%;
  }
}
