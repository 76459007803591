body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'ss-extrabold';
  src: url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Black.eot');
  src: url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Black.eot?#iefix') format('embedded-opentype'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Black.woff2') format('woff2'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Black.woff') format('woff'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Black.ttf') format('truetype'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Black.svg#SourceSerifPro-Black') format('svg');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'ss-bold';
  src: url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Bold.eot');
  src: url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Bold.eot?#iefix') format('embedded-opentype'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Bold.woff2') format('woff2'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Bold.woff') format('woff'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Bold.ttf') format('truetype'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Bold.svg#SourceSerifPro-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ss-regular';
  src: url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Regular.eot');
  src: url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Regular.eot?#iefix') format('embedded-opentype'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Regular.woff2') format('woff2'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Regular.woff') format('woff'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Regular.ttf') format('truetype'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Regular.svg#SourceSerifPro-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ss-light';
  src: url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Light.eot');
  src: url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Light.eot?#iefix') format('embedded-opentype'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Light.woff2') format('woff2'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Light.woff') format('woff'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Light.ttf') format('truetype'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-Light.svg#SourceSerifPro-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ss-semibold';
  src: url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-SemiBold.eot');
  src: url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-SemiBold.eot?#iefix') format('embedded-opentype'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-SemiBold.woff2') format('woff2'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-SemiBold.woff') format('woff'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-SemiBold.ttf') format('truetype'), url('https://cdn.mastersunion.org/assets/fontV2/SourceSerifPro-SemiBold.svg#SourceSerifPro-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}


/* --------------------------------------------
--------------------nunito sans----------------
------------------------------------------------  */

@font-face {
  font-family: 'ns-bold';
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Bold.eot');
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Bold.eot?#iefix') format('embedded-opentype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Bold.woff2') format('woff2'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Bold.woff') format('woff'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Bold.ttf') format('truetype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Bold.svg#NunitoSans-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ns-extrabold';
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-ExtraBold.eot');
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-ExtraBold.eot?#iefix') format('embedded-opentype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-ExtraBold.woff2') format('woff2'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-ExtraBold.woff') format('woff'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-ExtraBold.ttf') format('truetype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-ExtraBold.svg#NunitoSans-ExtraBold') format('svg');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ns-black';
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Black.eot');
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Black.eot?#iefix') format('embedded-opentype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Black.woff2') format('woff2'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Black.woff') format('woff'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Black.ttf') format('truetype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Black.svg#NunitoSans-Black') format('svg');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ns-italic';
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Italic.eot');
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Italic.eot?#iefix') format('embedded-opentype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Italic.woff2') format('woff2'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Italic.woff') format('woff'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Italic.ttf') format('truetype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Italic.svg#NunitoSans-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ns-regular';
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Regular.eot');
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Regular.eot?#iefix') format('embedded-opentype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Regular.woff2') format('woff2'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Regular.woff') format('woff'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Regular.ttf') format('truetype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Regular.svg#NunitoSans-Regular') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ns-light';
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Light.eot');
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Light.eot?#iefix') format('embedded-opentype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Light.woff2') format('woff2'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Light.woff') format('woff'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Light.ttf') format('truetype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-Light.svg#NunitoSans-Light') format('svg');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ns-semibold';
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-SemiBold.eot');
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-SemiBold.eot?#iefix') format('embedded-opentype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-SemiBold.woff2') format('woff2'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-SemiBold.woff') format('woff'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-SemiBold.ttf') format('truetype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-SemiBold.svg#NunitoSans-SemiBold') format('svg');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'ns-semibold-italic';
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-SemiBoldItalic.eot');
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-SemiBoldItalic.eot?#iefix') format('embedded-opentype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-SemiBoldItalic.woff2') format('woff2'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-SemiBoldItalic.woff') format('woff'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-SemiBoldItalic.ttf') format('truetype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-SemiBoldItalic.svg#NunitoSans-SemiBoldItalic') format('svg');
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'ns-bold-italic';
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-BlackItalic.eot');
  src: url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-BlackItalic.eot?#iefix') format('embedded-opentype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-BlackItalic.woff2') format('woff2'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-BlackItalic.woff') format('woff'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-BlackItalic.ttf') format('truetype'), url('https://cdn.mastersunion.org/assets/fontV2/NunitoSans-BlackItalic.svg#NunitoSans-BlackItalic') format('svg');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}



/* ================================= BRUTA FONTS =========================================== */


@font-face {
  font-family: 'bruta-bold';
  src: url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-Bold.eot');
  src: url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-Bold.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-Bold.woff2') format('woff2'),
    url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-Bold.woff') format('woff'),
    url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-Bold.ttf') format('truetype');
  font-style: bold;
  font-display: swap;
}

@font-face {
  font-family: 'bruta-semibold';
  src: url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-SemiBold.eot');
  src: url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-SemiBold.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-SemiBold.woff2') format('woff2'),
    url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-SemiBold.woff') format('woff'),
    url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-SemiBold.ttf') format('truetype');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'bruta-regular';
  src: url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-Regular.eot');
  src: url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-Regular.eot?#iefix') format('embedded-opentype'),
    url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-Regular.woff2') format('woff2'),
    url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-Regular.woff') format('woff'),
    url('https://cdn.mastersunion.org/assets/imgV2/BrutaProExtended-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

/* Mint Grotesk Trial Font Ends */


.popupWrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1200;
  background: rgba(0, 0, 0, 0.2);
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
  visibility: hidden;
  opacity: 0;
  transition: 0.1s;
  z-index: 999;
}

.popupWrapper.active {
  opacity: 1;
  visibility: visible;
  transition: 0.2s;
}

.popupContainer {
  width: 600px;
  margin: auto;
  background: var(--white);
  border: 1px solid #EEEEEE;
  border-radius: 15px;
  overflow: hidden;
}

.popupHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  border-bottom: 1px solid #EEEEEE;
}

.popupBody {
  padding: 20px;
  max-height: 500px;
  overflow: auto;
}

.TwoSections {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.flexend {
  justify-content: flex-end;
}

.popupFooter {
  padding: 20px;
  padding-top: 0;
}

.popupFooter button {
  font-size: 14px;
  padding: 10px 12px;
}

.buttonWrapper {
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}

.close {
  display: flex;
  align-items: center;
  padding: 2px;
  border: 1px solid var(--grey2);
  border-radius: 5px;
  cursor: pointer;
}

.secondMenu {
  padding: 10px;
  height: 50px;
  justify-content: space-between;
}

.wrapperBox {
  max-width: 100%;
  height: calc(100vh - 103px);
  overflow: auto;
  background: var(--grey2);
  padding: 20px;
  position: relative;
}

.wrapperBox.singleMenu,
.mainWrapper.singleMenu {
  height: calc(100vh - 51px);
}

.wrapperBox.doubleMenu,
.mainWrapper.doubleMenu {
  height: calc(100vh - 101px);
}


.dashboard {
  display: none;
}

.dashboard.active {
  display: block;
}

.mainWrapper {
  height: calc(100% - 102px);
  padding: 10px 0;
  background: var(--grey5);
  overflow-x: auto;
  width: 100%;
  position: relative;
}

.togglerBox {
  padding: 12px;
  background: var(--white);
  border-radius: 6px;
}

.togglerBox.active .homeTableScroller {
  max-height: 100vh;
  margin-top: 12px;
  transition: 0.4s ease-in-out;
}

.togglerBox:not(:first-child) {
  margin-top: 10px;
}

.togglerHeading {
  justify-content: space-between;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  cursor: pointer;
}

.togglerIcon {
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
  gap: 8px;
  color: var(--black3);
  cursor: pointer;
  transition: 0.4s ease-in-out;
}

.togglerBox .togglerIcon img {
  transition: 0.4s ease-in-out;
}

.togglerBox.active .togglerIcon img {
  transform: rotate(180deg);
  transition: 0.4s ease-in-out;
}

/* Table Start */

table {
  width: 100%;
  border-collapse: collapse;
  position: relative;
  /* min-height: calc(100vh - 145px); */
}

table.noDataTable {
  height: 100%;
  min-height: calc(100vh - 123px);
}

.blackHeadingTable {
  /* border-radius: 5px; */
  /* overflow: hidden; */
}

.blackHeadingTable thead {
  background: var(--darkblack);
}

.blackHeadingTable th {
  padding: 12px 20px;
}

.tableIconHeading {
  /* padding: 12px; */
  color: var(--white);
  font-weight: 500;
  font-size: 11px;
  line-height: 13px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
  text-transform: uppercase;
  min-width: max-content;
}

.blackHeadingTable td {
  padding: 18px 20px;
}

.blackHeadingTable td button{
  padding: 0;
}

.tableDetails {
  color: #121112;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  position: relative;
}

.tableDetails.hoverState {
  cursor: pointer;
}

.tableDetails button {
  line-height: normal;
}

.toolTip {
  position: relative;
  display: inline-block;
  cursor: pointer;
}

.toolTip:hover .toolTopBox {
  opacity: 1;
  visibility: visible;
  z-index: 9;
  transition: 0.4s ease-in-out;
}

.toolTopBox {
  position: absolute;
  left: calc(100% + 10px);
  min-width: max-content;
  top: -2px;
  filter: drop-shadow(0px 0px 20px rgba(0, 0, 0, 0.15));
  background: var(--darkblue);
  border-radius: 3px;
  color: var(--white);
  padding: 4px 8px;
  font-weight: 500;
  font-size: 13px;
  line-height: 120%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: 0.4s ease-in-out;
}

.toolTopBox::before {
  content: '';
  position: absolute;
  left: -3px;
  top: 8px;
  width: 8px;
  height: 8px;
  background: var(--darkblue);
  transform: rotate(45deg)
}

.blackHeadingTable tbody tr {
  background: var(--white);
  transition: 0.4s ease-in-out;
  border-bottom: 1px solid var(--grey2);
}

.blackHeadingTable tbody tr:nth-child(odd) {
  background: var(--grey4);
}

.blackHeadingTable tbody tr:nth-child(even) {
  background: var(--white);
}

.blackHeadingTable tbody tr:hover {
  background: var(--grey5);
  transition: 0.4s ease-in-out;
}

.homeTableScroller {
  max-height: 0;
  overflow: auto;
  margin-top: 0;
  transition: 0.4s ease-in-out;
}

.homeTableScroller::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 15px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

.tableDetails.redLink {
  font-weight: 500;
  font-size: 13px;
  line-height: 20px;
}

/* Table Ends */

.blueLink {
  color: var(--blue);
}

.greenLink {
  color: var(--green);
}

.redLink {
  color: var(--red);
}

.heading-4 {
  font-size: 16px;
  line-height: 150%;
  font-weight: 500;
}


.tableDetails.hoverState:hover {
  color: var(--blue);
  transition: 0.4s ease-in;
}

.loadMoreWrapper {
  width: 100%;
  margin-top: 20px;
  text-align: center;
  display: flex;
  justify-content: center;
  text-align: center;
  width: 100%;
}


.ytagGreen {
  color: var(--green);
  background: #E5F3EF;
  border-radius: 3px;
  font-size: 11px;
  padding: 4.5px 7px;
  line-height: 120%;
}

.ytagRed {
  color: var(--red);
  background: #FCEBEB;
  border-radius: 3px;
  font-size: 11px;
  padding: 4.5px 7px;
  line-height: 120%;
}

.ytagClear {
  color: var(--blue);
  background: transparent;
  border-radius: 3px;
  font-size: 12px;
  padding: 4.5px 7px;
  line-height: 120%;

}

.ytagBlue {
  color: var(--blue);
  background: rgba(0, 82, 204, 0.1);
  border-radius: 3px;
  font-size: 11px;
  padding: 4.5px 7px;
  line-height: 120%;

}

.ytagGrey {
  color: var(--black);
  background: #DFDFDF;
  border-radius: 3px;
  font-size: 12px;
  padding: 4.5px 7px;
  line-height: 120%;
}

.ytagYellow {
  color: var(--yellow);
  background: rgba(255, 184, 67, 0.1);
  border-radius: 3px;
  font-size: 11px;
  padding: 4.5px 7px;
  line-height: 120%;
}

.ytagpurple {
  color: rgba(82, 67, 170, 1);
  background: rgba(82, 67, 170, 0.1);
  border-radius: 3px;
  font-size: 11px;
  padding: 4.5px 7px;
  line-height: 120%;
}


@media(max-width: 767px) {
  .popupContainer {
    margin: 0 10px;
  }

  .wrapperBox {
    padding: 20px 15px;
  }

  .wrapperBox.doubleMenu {
    padding-bottom: 50px;
  }

  .blackHeadingTable td{
    min-width: 200px;
  }
}