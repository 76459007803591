.authenticationLeft{
    width: 100%;
    max-width: 45%;
    /* padding: 60px; */
}

.welcomeHeading{
    font-weight: 600;
    font-size: 42px;
    line-height: 120%;
    color: var(--black);
}

.welcomeData{
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--black4);
    max-width: 315px;
    display: inline-block;
}

.loginWrap{
    max-width: 400px;
    margin: 0 auto;
    margin-top: 30px;
}

.loginWrap .formGroups label{
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: var(--black1);
}

.loginWrap .formGroups::placeholder{
    font-weight: 500;
}

.redLink{
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: var(--red);
}

.textRight{
    text-align: right;
}

/* new login screen */

.greyBoxWrap{
    width: 100%;
    height: 100%;
    background: var(--grey3);
    justify-content: center;
    gap: 60px;
    flex-direction: column;
    padding: 20px;
}

.loginHeading{
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    color: var(--black);
}

.loginText{
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--black4);
}

.textWrap{
    text-align: center;
}

@media(max-width: 767px){
    .authenticationWrapper{
        flex-direction: column;
        gap: 20px;
    }
    .LottieLogin,.LoginWrapperBox{
        max-width: 100%;
    }

    .LoginBoxContent{
        margin: 20px 10px;
    }
}

/* new login screen ends */

@media (min-width: 768px) and (max-width: 1600px){
    .greyBoxWrap{
        gap: 30px;
    }
}