.contactUsWrapper {
	.headerlogo {
		padding: 16px 16px 10px;
		border-bottom: 1px solid #e5e5e5;
		cursor: pointer;
	}

	.contactusContainer {
		padding: 50px 0;
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		width: 100%;

		.header {
			display: flex;
			flex-direction: column;
			gap: 20px;
			align-items: center;
			justify-content: center;

			h2 {
				color: black;
				font-size: 20px;
				font-weight: 600;
			}

			p {
				font-size: 14px;
				font-weight: 400;
			}
		}
	}

	.contactDetailsWrap {
		display: flex;
		gap: 20px;
		width: 80%;
		margin-top: 30px;

		justify-content: center;

		.box {
			width: 100%;
			max-width: 347px;
			border-radius: 10px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			padding: 24px;
			border: 1px solid #dddd;
			gap: 23px;

			h4 {
				color: #585858;
				font-size: 16px;
				font-weight: 600;
			}

			p {
				font-size: 14px;
				font-weight: 400;
				color: #585858;
			}

			.contentWrap {
				display: flex;
				gap: 8px;
				flex-direction: column;
			}
		}
	}
}

@media (max-width: 768px) {
	.contactUsWrapper {
		.contactusContainer {
			max-width: 768px;
			width: 100%;
			padding: 0 15px;

			.header {
				width: 80%;
				margin-top: 30px;
			}
		}

		.contactDetailsWrap {
			flex-direction: column;
			width: 100%;

			.box {
				width: 100%;
				max-width: 100%;
			}
		}
	}
}
