.authenticationWrapper {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100vh;
    overflow: hidden;
}

.LottieLogin {
    width: 100%;
    max-width: calc(100% - 40%);
    height: 100%;
    display: flex;
    justify-content: flex-end;
}

.LoginWrapperBox {
    width: 100%;
    height: 100%;
    max-width: 40%;
    background: rgba(10, 68, 156, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('/src/assets/img/logingraphic.png');
    background-repeat: no-repeat;
    background-position: bottom left;
    object-fit: contain;
}

.LoginBoxContent .text16 {
    color: #696F79;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.LoginBoxContent .text16 span {
    color: #0052CC;
    border-bottom: 1px solid #0052CC;
    cursor: pointer;
    transition: 0.4s;
}

.LoginBoxContent .text16 span:hover{
    opacity: 0.8;
}

.LoginBoxContent {
    width: 100%;
    max-width: 450px;
    border-radius: 8px;
    background: #fff;
    padding: 48px 33px;
    border: 1px solid #C2E0FA;
}

.InputwrapBox {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 25px;
}

.resettext {
    color: #0052CC;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    transition: 0.4s;
    border-bottom: 1px solid transparent;
}

.resetPasstext{
    width: 100%;
    display: flex;
    justify-content: flex-end;

}

.resettext:hover {
    border-bottom: 1px solid #0052CC;
    cursor: pointer;

}

.authenticationLeft {
    width: 100%;
    max-width: 45%;
    background: #0A449C;
    height: 100%;
}

.welcomeHeading {
    font-weight: 600;
    font-size: 42px;
    line-height: 120%;
    color: var(--black);
}

.signtext {
    color: #0052CC;
    font-size: 24px;
    font-weight: 600;
    line-height: 120%;
}

.headinglogin {
    margin-bottom: 28px;
}

.welcomeData {
    font-weight: 400;
    font-size: 14px;
    line-height: 150%;
    color: var(--black4);
    max-width: 315px;
    display: inline-block;
}

.loginWrap {
    max-width: 400px;
    margin: 0 auto;
    margin-top: 30px;
}

.loginWrap .formGroups label {
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    line-height: 120%;
    color: var(--black1);
}

.loginWrap .formGroups:not(:first-child) {
    margin-top: 25px;
}

.headinglogin .text12 {
    font-size: 16px;
    font-weight: 400;
    color: #525252;
}

.loginWrap .formGroups input {
    min-height: 52px;
    font-weight: 500;
}

.loginWrap .formGroups::placeholder {
    font-weight: 500;
}

.redLink {
    font-weight: 500;
    font-size: 16px;
    line-height: 120%;
    color: var(--red);
}

.textRight {
    text-align: right;
}

/* old code */

.authenticationRight {
    height: 100%;
    width: 100%;
    max-width: calc(100% - 45%);
    background: var(--white);
    background-repeat: no-repeat;
    background-position: bottom right;
}

.LoginMainWrap {
    display: flex;
    justify-content: center;
    align-items: center;
}


/* old code ends */

/* new login screen */

.greyBoxWrap {
    width: 100%;
    height: 100%;
    background: var(--grey3);
    justify-content: center;
    gap: 60px;
    flex-direction: column;
    padding: 20px;
}

.loginHeading {
    font-weight: 600;
    font-size: 28px;
    line-height: 120%;
    color: var(--black);
}

.loginText {
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    color: var(--black4);
}

.textWrap {
    text-align: center;
}

.forget {
    cursor: pointer;
}

.androidText{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 29px;
    color:#0052CC;
    font-weight: 500;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
}
.androidText:visited{
    color:#0052CC;
}

@media(max-width: 767px){
    .authenticationWrapper{
        overflow: auto;
    }
}


/* new login screen ends */

@media (min-width: 768px) and (max-width: 1600px) {
    .greyBoxWrap {
        gap: 30px;
    }
}

.chatWrap{
    position: absolute;
    right: 3%;
    bottom: 3%;
    display: flex;
    align-items: end;
    flex-direction: column;
    gap: 5px;
    cursor: pointer;
    .dialogueBox{
        background: #252525;
        padding: 8px 12px;
        color: white;
        border-radius: 10px;
        left: 20%;
        width: fit-content;
        opacity: 0;
        display: none;
    }
}

.chatWrap:hover .dialogueBox{
    opacity: 1;
    display: block;

}