@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800&display=swap");

.container {
	max-width: 100%;
	width: 100%;
	/* padding-left: 15px;
    padding-right: 15px; */
	margin: 0 auto;
}

.flexbox {
	display: flex;
	position: relative;
	align-items: center;
	/* justify-content: inherit; */
}

.ml5 {
	margin-left: 5px;
	margin-left: 5px;
}

.pointer {
	cursor: pointer;
	cursor: pointer;
}
.imgmeetRoom {
	width: 100%;
	max-width: 210px;
	object-fit: cover;
	object-position: center;
	border-radius: 6px;
	height: 120px;
}

/* skleton loader */

@keyframes skeleton-glare {
	to {
		transform: translateX(100%);
	}
}

.skletonLoader {
	position: relative;
	overflow: hidden;
	width: 100%;
	padding: 10px;
}

.skletonLoader::after {
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background: var(--glare-bg);
	transform: translateX(-100%);
	animation: skeleton-glare 1.75s infinite ease-out;
	z-index: 1;
}

.w100 {
	width: 100%;
}

::placeholder {
	font-weight: 500;
	font-size: 14px;
	line-height: 120%;
	color: var(--grey);
	font-family: "inter";
}

.SmenuMid {
	font-weight: 400;
	font-size: 14px;
	line-height: 24px;
	color: var(--black3);
}

.multiSelectContainer li {
	font-size: 13px;
	font-weight: 400;
	color: var(--black);
}

.multiSelectContainer input::placeholder {
	color: #525252;
	font-size: 14px;
	font-weight: 400;
	line-height: 120%;
}

.text12 {
	font-size: 12px;
	font-weight: 500;
	line-height: 150%;
}

.text14 {
	font-size: 14px;
	font-weight: 500;
	line-height: 150%;
}

.title13 {
	color: #121112;
	font-size: 13px;
	font-weight: 500;
	line-height: 120%;
}

.popupHeading {
	font-size: 14px;
	font-weight: 400;
	color: #404040;
	line-height: 150%;
}

.pointer {
	cursor: pointer;
}

.blockAreaTable {
	background: var(--white);
	border-radius: 5px;
	padding-bottom: 10px;
	height: calc(100vh - 142px);
}

.tabledivWrapper {
	overflow: auto;
	border-radius: 5px;
	max-height: calc(100vh - 150px);
}

.tabledivWrapper table thead tr th {
	position: sticky;
	background: var(--black);
	top: 0;
}

.playStoreImage{
	width: 100%;
	max-width: 140px;
}

@media (max-width: 1024px) and (max-width: 1280px) {
	::placeholder {
		font-size: 12px;
	}
}

@media (max-width: 767px) {
	.tabledivWrapper {
		max-height: calc(100vh - 185px);
	}

	.blockAreaTable {
		height: calc(100vh - 172px);
	}
}
