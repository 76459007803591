.notificationWrap {
    min-height: 50px;
    background: var(--white);
    border-bottom: 1px solid var(--grey2);
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 5px 20px;
    z-index: 9;
    position: relative;
}

.notificationWrap svg {
    cursor: pointer;
}

.welcomeText {
    font-size: 16px;
    line-height: 120%;
    font-weight: normal;
    color: var(--black5);

    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
}

.menuIconBar {
    display: none;
}

@media(max-width: 767px) {
    .menuIconBar {
        display: block;
    }
}