.createRoomContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.ResetpopupWrap .popupContainer {
    max-width: 500px;
}

.ResetpopupWrap .popupBody {
    padding: 40px 20px;
}

.ResetpopupWrap .formGroups input{
    height: 50px;
    border-radius: 8px;
    border: 1px solid #E0EFFD;
    background: #F7FAFF;
}

.formGroups label{
    font-weight: 400;
}

.createRoomBody {
    width: 100%;
}

.input-field label {
    display: block;
    position: relative;
    font-weight: 400;
    font-size: 13px;
    line-height: 120%;
    color: #121112;
    margin-bottom: 5px;
}

.input-field input[type='text'],
input[type='number'] {
    background: #f5f5f5;
    min-height: 40px;
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid var(--grey3);
    border-radius: 10px;
}

.input-field input[type='text']:focus {
    outline: none;
    border: 1px solid var(--grey3);
}

.input-field input[type='number']:focus-visible,
select:focus-visible {
    outline: none;
    border: 1px solid var(--grey3);
}

.createRoomFooter {
    display: flex;

    justify-content: flex-end;
    margin-top: 2rem;
}

.select {
    background: #f5f5f5;
    min-height: 40px;
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid var(--grey3);
    border-radius: 10px;
}

.font-13 {
    font-size: 13px;
}

.uploadfield {
    display: flex;
    gap: 20px;
    margin-top: 10px;

}

.uploadfield .imgPreview {
    max-width: 90px;
    max-height: 59px;
    width: 100%;
    border-radius: 4px;
}