@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700;800;900&display=swap');

*{
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Inter', sans-serif;
}

@font-face {
    font-family: "FontName"; 
    font-style: normal;
    font-weight: normal; /* normal | 300 | 400 | 600 | bold | etc */
    src: url("../fonts/static/Nunito-SemiBold.ttf") format("truetype");
  }

img{
    max-width: 100%;
}

a{
    text-decoration: none;
}

:root{
    --white: #ffffff;

    --darkblue: #172B4D;
    --blue: #0052CC !important;
    --lightBlue: #0052CC;
    --lighterBlue:#c4dcff;
    --yellow: #FFB843 !important;
    --voilet: #5243AA;
    --darkOrange: #DE350B !important;
    --orange: #FF540D !important;
    --red: #E53935;
    --green: #00875A !important;

    /* black shades */

    --black: #171717;
    --darkblack: #121212;
    --black1: #262626;
    --black2: #404040;
    --black3: #525252;
    --black4: #737373;
    --black5: #121112;
    --black6: #111928;

    /* grey shades */

    --grey: #A3A3A3;
    --grey1: #D4D4D4;
    --grey2: #E5E5E5;
    --grey3: #F5F5F5;
    --grey4: #FAFAFA;
    --grey5: #EEEEEE;
    --grey6: #6B7280;

    /* skleton loader */

    --glare-bg: linear-gradient(90deg, transparent, hsla(0, 0%, 100%, 0.4), transparent);

    --success-1: #DDEBE5;
    --success-2: #A4CBBB;
    --success-3: #77B098;
    --success-4: #1C7C54;
    --success-5: #19704C;
    --success-6: #166343;

    --danger-1: #FADFE1;
    --danger-2: #F2A9AE;
    --danger-3: #EC7F86;
    --danger-4: #DF2935;
    --danger-5: #C92530;
    --danger-6: #B2212A;

    --warning-1: #FEF7DB;
    --warning-2: #FCE794;
    --warning-3: #FED8EC;
    --warning-4: #FFF4CE;
    --warning-5: #FFF4CE;
    --warning-6: #FDE7E9;
    --warning-7: #FDCA40;

    --primary-1: #D9E9F9;
    --primary-2: #99C4EE;
    --primary-3: #66A7E5;
    --primary-4: #006CD4;
    --primary-5: #0061BF;
    --primary-6: #0056AA;
}


/* margin-top classes starts*/

.mt-2{
    margin-top: 2px;
}

.mt-5{
    margin-top: 5px;
}

.mt-8{
    margin-top: 8px;
}
.mt-10{
    margin-top: 10px;
}

.mt-15{
    margin-top: 15px;
}

.mt-20{
    margin-top: 20px;
}

.mt-25{
    margin-top: 25px;
}

.mt-30{
    margin-top: 30px;
}

.mt-35{
    margin-top: 35px;
}

.mt-40{
    margin-top: 40px;
}

.mt-45{
    margin-top: 45px;
}

.mt-50{
    margin-top: 50px;
}

/* margin-top classes ends */

/* margin-bottom classes starts*/

.mb-5{
    margin-bottom: 5px;
}

.mb-8{
    margin-bottom: 8px;
}

.mb-10{
    margin-bottom: 10px;
}

.mb-15{
    margin-bottom: 15px;
}

.mb-20{
    margin-bottom: 20px;
}

.mb-25{
    margin-bottom: 25px;
}

.mb-30{
    margin-bottom: 30px;
}

.mb-35{
    margin-bottom: 35px;
}

.mb-40{
    margin-bottom: 40px;
}

.mb-45{
    margin-bottom: 45px;
}

.mb-50{
    margin-bottom: 50px;
}

/* margin-bottom classes ends */

/* flex gap start */

.gap5{
    gap: 5px;
}

.gap10{
    gap: 10px;
}

.gap15{
    gap: 15px;
}

.gap20{
    gap: 20px;
}

/* flex gap ends */